@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700');
@import './_01_mixins.scss';
@import './_02_variables';
@import './_03_core.scss';
@import './_04_plugins.scss';
@import './_05_views.scss';

/** your custom css code **/
/* 
html {
    background-color: $theme-color-1 !important;
}
*/
body {
    padding-bottom: 40px;
}
main {
    margin-top: 90px;    
}
.navbar {    
    height: 60px;
    padding: 0.5rem 0;
}
.sidebar {    
    height: calc(100% - 60px);
    padding-top: 60px;    
}
.sidebar .main-menu {
    height: calc(100% - 60px);
}
.text-initial {
    text-align: initial !important;
}
.just-al {
    text-align: left;
}
.broadcast-form {
    font-size: o.8rem;
}
.broadcard {
    border-radius: 0.75rem;
    max-width: 600px;
}
.broadcard .dropzone {
    border-style: dashed !important;
    min-height: 140px !important;
}
.dropzone.dz-clickable {
    cursor: pointer;
}
.dz-message {
    text-align: center;
}
.broadtext {
    min-height: 5rem;
}
@media (max-width: 767px) {
    .sidebar {
        display: none;
    }
}

.dz-image-preview {
    width: auto !important;
    height: auto !important;
}

footer.page-footer {
    background-color: transparent;
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 60px;
}